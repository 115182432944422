<template>
  <div class="home-container"
    ><div class="home-navbar"
      ><header data-thq="thq-navbar" class="home-navbar-interactive"
        ><div class="home-items"
          ><div class="home-links"
            ><a href="#research" class="home-link nav-link">About</a
            ><a href="#portfolio" class="home-link01 nav-link">Portfolio</a></div
          ></div
        ><img alt="image" src="/jashdkajdas-200h.png" class="home-image" /><div
          class="home-items1"
          ><div class="home-links1"
            ><a href="#contact" class="home-link02 nav-link">Contact</a
            ><a
              href="https://twitter.com/Supra_Capital"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link03 nav-link"
              >Twitter</a
            ></div
          ></div
        ><div data-thq="thq-burger-menu" class="home-burger-menu"
          ><button class="home-button button"
            ><img alt="image" src="/hamburger.svg" class="home-image1" /><span
              class="home-text"
              >Start a project</span
            ></button
          ></div
        ><div data-thq="thq-mobile-menu" class="home-mobile-menu"
          ><div data-thq="thq-mobile-menu-nav" data-role="Nav" class="home-nav"
            ><div class="home-top"
              ><div class="home-branding"
                ><img
                  alt="image"
                  src="/jashdkajdas-200h.png"
                  class="home-image2"
                /><span class="home-company">SUPRA CAPITAL</span></div
              ><div data-thq="thq-close-menu" class="home-menu-close"
                ><svg viewBox="0 0 1024 1024" class="home-icon">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  ></path></svg></div></div
            ><div class="home-items2"
              ><div class="home-links2"
                ><a href="#research" class="home-link04 nav-link">About</a
                ><span class="home-text01 nav-link">Portfolio</span
                ><a href="#team" class="home-link05 nav-link">Contact</a
                ><a
                  href="https://twitter.com/Supra_Capital"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="home-link06 nav-link"
                  >Twitter</a
                ></div
              ></div
            ></div
          ></div
        ></header
      ></div
    ><header class="home-hero"
      ><div class="home-header"
        ><h1 class="home-title">Supra Capital</h1
        ><p class="home-description"
          >Providing early projects with the tools needed to go beyond </p
        ></div
      ></header
    ><div class="home-blog"
      ><div class="home-header1"
        ><h2 id="research" class="heading"><span>About US</span><br /></h2></div
      ><div class="home-grid"
        ><app-article
          title="Vision"
          description="We believe that the decentralized and open nature of Web3 will fundamentally change the way we experience digital life, causing significant disruption to the current status quo."
          rootClassName="article-root-class-name"
        ></app-article
        ><app-article
          title="Mission"
          description="We drive this transformation by working hand-in-hand with unique and ground-breaking projects to change the future substantially."
          rootClassName="article-root-class-name1"
        ></app-article
        ><app-article
          title="Values"
          description="We aim to cultivate enduring partnerships with our collaborators and portfolio companies, rooted in our core values of trust, open-mindedness, and transparency."
          rootClassName="article-root-class-name5"
        ></app-article></div></div
    ><div class="home-portfolio"
      ><div class="home-header2"
        ><h2 id="portfolio" class="home-title2 heading"
          ><span>PORTFOLIO</span><br /></h2></div
      ><div class="home-container1"
        ><a href="https://exeedme.com/" target="_blank" rel="noreferrer noopener"
          ><portfolio-logo
            imageSrc="/6-200h.png"
            rootClassName="rootClassName"
            class="home-component03"
          ></portfolio-logo></a
        ><a
          href="https://www.dotmoovs.com/"
          target="_blank"
          rel="noreferrer noopener"
          ><portfolio-logo
            imageSrc="/5-200h.png"
            rootClassName="rootClassName2"
            class="home-component04"
          ></portfolio-logo></a
        ><a
          href="https://www.lympid.io/"
          target="_blank"
          rel="noreferrer noopener"
          ><portfolio-logo
            imageSrc="/7-200h.png"
            rootClassName="rootClassName3"
            class="home-component05"
          ></portfolio-logo></a
        ><a
          href="https://www.zharta.io/"
          target="_blank"
          rel="noreferrer noopener"
          ><portfolio-logo
            imageSrc="/gm%20lui%C3%AC%C2%81s%20(81)-200h.png"
            rootClassName="rootClassName4"
            class="home-component06"
          ></portfolio-logo></a></div
      ><div class="home-container2"></div
      ><div class="home-container3"
        ><portfolio-logo
          imageSrc="/6-200h.png"
          rootClassName="rootClassName1"
          class="home-component07"
        ></portfolio-logo></div
      ><div class="home-container4"
        ><portfolio-logo
          imageSrc="/7-200h.png"
          rootClassName="rootClassName5"
          class="home-component08"
        ></portfolio-logo></div></div
    ><div class="home-testimonial"
      ><div class="home-container5"
        ><h1 id="team" class="home-text06 heading"><span>team</span><br /></h1
        ><div class="home-container6"
          ><app-varela
            name="Francisco Varela"
            role="Founding Partner"
            pictureSrc="/captura%20de%20ecra%C3%AC%C2%83%202024-04-02%2C%20a%C3%AC%C2%80s%2002.09.06-200h.png"
            rootClassName="rootClassName2"
          ></app-varela
          ><app-luis
            name="Luís Pinto"
            role="Founding partner"
            pictureSrc="/luissupra-200h.jpeg"
            rootClassName="luis-root-class-name"
          ></app-luis
          ><app-windoh
            name="Diogo Silva"
            role="Founding Partner"
            pictureSrc="/diogosilvasupra-200h.jpeg"
            rootClassName="windoh-root-class-name"
          ></app-windoh></div></div></div
    ><div class="home-social-bar"
      ><a
        href="https://twitter.com/Supra_Capital"
        target="_blank"
        rel="noreferrer noopener"
        class="home-link11"
        ><svg
          id="contact"
          viewBox="0 0 950.8571428571428 1024"
          class="home-icon2"
        >
          <path
            d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
          ></path></svg></a></div
    ><footer class="home-footer"
      ><div class="home-container7"
        ><img alt="image" src="/jashdkajdas-200h.png" class="home-image3" /><span
          class="home-company1"
          >Supra Capital</span
        ></div
      ><span class="home-text09">© 2024 All Rights Reserved</span></footer
    ></div
  >
</template>

<script>
import AppArticle from '../components/article'
import PortfolioLogo from '../components/portfolio-logo'
import AppVarela from '../components/varela'
import AppLuis from '../components/luis'
import AppWindoh from '../components/windoh'

export default {
  name: 'Home',
  props: {},
  components: {
    AppArticle,
    PortfolioLogo,
    AppVarela,
    AppLuis,
    AppWindoh,
  },
  metaInfo: {
    title: 'Supra Capital',
    meta: [
      {
        property: 'og:title',
        content: 'Supra Capital',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: ##151515;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #151515;
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: 1280px;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.home-items {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: flex-end;
}
.home-link {
  text-decoration: none;
}
.home-link01 {
  text-decoration: none;
}
.home-image {
  width: 47px;
  height: 47px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.home-items1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-links1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: 0nits;
  flex-direction: row;
  justify-content: flex-end;
}
.home-link02 {
  text-decoration: none;
}
.home-link03 {
  text-decoration: none;
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-button {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding-top: 20px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 20px;
  background-color: #235536;
}
.home-image1 {
  width: 100px;
  display: none;
  object-fit: cover;
}
.home-text {
  color: #ffffff;
  display: flex;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #151515;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-branding {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-image2 {
  width: 200px;
  object-fit: cover;
}
.home-company {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.home-menu-close {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.home-items2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-links2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link04 {
  text-decoration: none;
}
.home-text01 {
  text-decoration: none;
}
.home-link05 {
  text-decoration: none;
}
.home-link06 {
  text-decoration: none;
}
.home-hero {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: #151515;
}
.home-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1280px;
  margin-top: var(--dl-space-space-sixunits);
  align-items: flex-start;
  padding-top: 150px;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: 170px;
}
.home-title {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 128px;
  max-width: auto;
  align-self: center;
  font-family: "Anta";
  font-weight: normal;
  line-height: 115px;
  text-transform: uppercase;
}
.home-description {
  color: rgb(255, 255, 255);
  width: auto;
  font-size: 24px;
  max-width: auto;
  align-self: center;
  text-align: left;
  line-height: 36px;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: var(--dl-space-space-sixunits);
}
.home-blog {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1280px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.home-header1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #151515;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  border-bottom-width: 1px;
}
.home-grid {
  flex: 1;
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: auto auto auto;
}
.home-portfolio {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-color: #151515;
}
.home-header2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  border-bottom-width: 1px;
}
.home-title2 {
  color: #ffffff;
}
.home-container1 {
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-self: stretch;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-component03 {
  text-decoration: none;
}
.home-component04 {
  text-decoration: none;
}
.home-component05 {
  text-decoration: none;
}
.home-component06 {
  text-decoration: none;
}
.home-container2 {
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-self: stretch;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-container3 {
  width: auto;
  height: auto;
  display: none;
  grid-gap: var(--dl-space-space-twounits);
  align-self: stretch;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-component07 {
  text-decoration: none;
}
.home-container4 {
  width: auto;
  height: auto;
  display: none;
  grid-gap: var(--dl-space-space-twounits);
  align-self: stretch;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-component08 {
  text-decoration: none;
}
.home-testimonial {
  width: 100%;
  display: none;
  justify-content: center;
  background-color: #151515;
}
.home-container5 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.home-text06 {
  color: #151515;
  align-self: flex-start;
}
.home-container6 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  padding-top: var(--dl-space-space-threeunits);
  grid-template-columns: 1fr 1fr 1fr;
}
.home-social-bar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-link11 {
  display: contents;
}
.home-icon2 {
  fill: #151515;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: 0px;
  text-decoration: none;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #151515;
}
.home-container7 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.home-image3 {
  width: 47px;
  height: 47px;
  object-fit: cover;
  margin-right: var(--dl-space-space-halfunit);
}
.home-company1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-family: "Anta";
}
.home-text09 {
  color: rgb(255, 255, 255);
}
@media(max-width: 991px) {
  .home-navbar-interactive {
    padding-top: var(--dl-space-space-twounits);
  }
  .home-button {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-image1 {
    width: 18px;
    height: 18px;
    display: flex;
  }
  .home-text {
    display: none;
  }
  .home-header {
    height: 100%;
    padding-bottom: var(--dl-space-space-sixunits);
    justify-content: center;
  }
  .home-title {
    font-size: 90px;
    text-align: center;
    line-height: 36px;
  }
  .home-description {
    font-size: 16px;
    line-height: 24px;
  }
  .home-blog {
    gap: var(--dl-space-space-threeunits);
    padding-top: 0px;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-header1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-top-width: 1px;
  }
  .home-grid {
    display: flex;
    grid-gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-top-width: 1px;
  }
  .home-container1 {
    grid-template-columns: 1fr 1fr;
  }
  .home-container2 {
    grid-template-columns: 1fr 1fr;
  }
  .home-container3 {
    grid-template-columns: 1fr 1fr;
  }
  .home-container4 {
    grid-template-columns: 1fr 1fr;
  }
  .home-text06 {
    text-align: center;
  }
  .home-container6 {
    grid-template-columns: 1fr;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-items {
    display: none;
  }
  .home-items1 {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-button {
    padding: 12px;
  }
  .home-image1 {
    width: 14px;
    height: 14px;
  }
  .home-header {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-portfolio {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container5 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-company1 {
    font-size: 18px;
    line-height: 20px;
  }
  .home-text09 {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-social-bar {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 430px) {
  .home-navbar-interactive {
    padding-top: var(--dl-space-space-twounits);
  }
  .home-burger-menu {
    padding-left: 300px;
  }
  .home-button {
    background-color: #151515;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-image2 {
    width: 60px;
    height: 57px;
  }
  .home-company {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-family: Anta;
    font-weight: 500;
  }
  .home-link05 {
    text-decoration: none;
  }
  .home-header {
    padding-top: var(--dl-space-space-fiveunits);
  }
  .home-title {
    font-size: 40px;
    line-height: 70px;
  }
  .home-description {
    font-size: 20px;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
  }
  .home-portfolio {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container1 {
    grid-gap: var(--dl-space-space-halfunit);
  }
  .home-container2 {
    grid-gap: var(--dl-space-space-halfunit);
  }
  .home-container3 {
    display: none;
    grid-gap: var(--dl-space-space-halfunit);
  }
  .home-container4 {
    grid-gap: var(--dl-space-space-halfunit);
  }
  .home-container5 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-icon2 {
    width: var(--dl-size-size-small);
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-container7 {
    margin-top: var(--dl-space-space-twounits);
  }
  .home-text09 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
}
</style>

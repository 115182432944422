<template>
  <div class="article-service" v-bind:class="rootClassName"
    ><h3 class="article-title">{{ title }}</h3
    ><span class="article-description">{{ description }}</span></div
  >
</template>

<script>
export default {
  name: 'Article',
  props: {
    title: {
      type: String,
      default: 'Branding',
    },
    rootClassName: String,
    description: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
    },
  },
}
</script>

<style scoped>
.article-service {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 350px;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.article-title {
  font-size: 40px;
  font-style: normal;
  font-family: "Anta";
  font-weight: 500;
  line-height: 36px;
  white-space: nowrap;
}
.article-description {
  font-size: 22px;
  line-height: 36px;
}



@media(max-width: 991px) {
  .article-service {
    gap: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .article-title {
    font-size: 18px;
    line-height: 16px;
  }
  .article-description {
    font-size: 14px;
    line-height: 21px;
  }
  .article-root-class-name {
    max-width: 100%;
  }
  .article-root-class-name1 {
    max-width: 100%;
  }
  .article-root-class-name5 {
    max-width: 100%;
  }
}
</style>

<template>
  <div class="portfolio-logo-feature-card" v-bind:class="rootClassName"
    ><img :alt="imageAlt" :src="imageSrc" class="portfolio-logo-image"
  /></div>
</template>

<script>
export default {
  name: 'PortfolioLogo',
  props: {
    title: {
      type: String,
      default: 'Lorem ipsum',
    },
    imageSrc: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1512295767273-ac109ac3acfa?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHllbGxvdyUyMHRlY2h8ZW58MHx8fHwxNjI2MjU1NDk0&ixlib=rb-1.2.1&w=300',
    },
    rootClassName: String,
    imageAlt: {
      type: String,
      default: 'image',
    },
  },
}
</script>

<style scoped>
.portfolio-logo-feature-card {
  width: auto;
  display: flex;
  padding: 0px;
  max-width: auto;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.portfolio-logo-feature-card:hover {
  transform: scale(1.02);
}
.portfolio-logo-image {
  width: var(--dl-size-size-xxlarge);
  height: var(--dl-size-size-large);
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}







@media(max-width: 1200px) {
  .portfolio-logo-image {
    width: var(--dl-size-size-xxlarge);
  }
}
@media(max-width: 767px) {
  .portfolio-logo-feature-card {
    padding: 0px;
  }
}
@media(max-width: 430px) {
  .portfolio-logo-image {
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-medium);
  }
}
</style>

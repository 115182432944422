import { render, staticRenderFns } from "./varela.vue?vue&type=template&id=48fa815c&scoped=true"
import script from "./varela.vue?vue&type=script&lang=js"
export * from "./varela.vue?vue&type=script&lang=js"
import style0 from "./varela.vue?vue&type=style&index=0&id=48fa815c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48fa815c",
  null
  
)

export default component.exports
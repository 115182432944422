<template>
  <div class="luis-testimonial-card" v-bind:class="rootClassName"
    ><div class="luis-testimonial"
      ><img :alt="pictureAlt" :src="pictureSrc" class="luis-image" /><span
        class="luis-text"
        >{{ name }}</span
      ><span class="luis-text1">{{ role }}</span
      ><div class="luis-container"
        ><a
          href="https://twitter.com/luispint0x"
          target="_blank"
          rel="noreferrer noopener"
          class="luis-link"
          ><svg viewBox="0 0 877.7142857142857 1024" class="luis-icon">
            <path
              d="M731.429 348.571c-21.714 9.714-44.571 16-69.143 19.429 25.143-14.857 44-38.857 53.143-66.857-23.429 13.714-49.143 24-76.571 29.143-21.714-23.429-53.143-37.714-87.429-37.714-66.286 0-120 53.714-120 120 0 9.143 0.571 18.857 2.857 27.429-100-5.143-188.571-52.571-248-125.714-10.286 17.714-16.571 38.857-16.571 60.571 0 41.714 19.429 78.286 52 100-20-0.571-38.857-6.286-57.143-14.857v1.143c0 58.286 44 106.857 98.857 117.714-10.286 2.857-18.286 4.571-29.143 4.571-7.429 0-14.857-1.143-22.286-2.286 15.429 47.429 59.429 82.286 112 83.429-41.143 32-92.571 51.429-149.143 51.429-9.714 0-19.429-0.571-28.571-1.714 53.143 33.714 116 53.714 184 53.714 220.571 0 341.714-182.857 341.714-341.714 0-5.143 0-10.286-0.571-15.429 23.429-16.571 44-37.714 60-62.286zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
            ></path></svg></a
        ><a
          href="https://www.linkedin.com/in/luis-pedro-pinto/"
          target="_blank"
          rel="noreferrer noopener"
          class="luis-link1"
          ><svg viewBox="0 0 877.7142857142857 1024" class="luis-icon2">
            <path
              d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
            ></path></svg></a></div></div
  ></div>
</template>

<script>
export default {
  name: 'Luis',
  props: {
    pictureAlt: {
      type: String,
      default: 'profile',
    },
    name: {
      type: String,
      default: 'Jane Doe',
    },
    rootClassName: String,
    pictureSrc: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&ixlib=rb-1.2.1&w=200',
    },
    role: {
      type: String,
      default: 'SOFTWARE ENGINEER',
    },
  },
}
</script>

<style scoped>
.luis-testimonial-card {
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.luis-testimonial {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.luis-image {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-unit);
}
.luis-text {
  font-size: 1.5rem;
  font-family: "Anta";
  font-weight: 300;
  margin-bottom: var(--dl-space-space-halfunit);
}
.luis-text1 {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
}
.luis-container {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.luis-link {
  display: contents;
}
.luis-icon {
  fill: #151515;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.luis-link1 {
  display: contents;
}
.luis-icon2 {
  fill: #151515;
  width: 24px;
  height: 24px;
  text-decoration: none;
}

@media(max-width: 991px) {
  .luis-text1 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .luis-testimonial-card {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 430px) {
  .luis-testimonial-card {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .luis-text {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .luis-text1 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
</style>
